var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center justify-left gap-2" },
    [
      _vm.showIcon
        ? _c("vs-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom-start",
                value: {
                  content: "Definir pontuação",
                  delay: { show: 1000, hide: 100 },
                },
                expression:
                  "{\n      content: 'Definir pontuação',\n      delay: { show: 1000, hide: 100 },\n    }",
                modifiers: { "bottom-start": true },
              },
            ],
            class: "footer-button text-inherit " + _vm.buttonClasses,
            attrs: { type: "filled", icon: _vm.icon },
            on: {
              "!click": function ($event) {
                $event.stopPropagation()
                return _vm.openInput.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      !_vm.showInput
        ? _c(
            "div",
            {
              staticClass: "flex-grow",
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.openInput.apply(null, arguments)
                },
              },
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _c("vs-input", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: _vm.inputMask,
            expression: "inputMask",
          },
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInput,
            expression: "showInput",
          },
        ],
        ref: "input",
        staticClass: "expandable-input flex-grow",
        attrs: { type: _vm.inputType, placeholder: _vm.placeholder },
        on: {
          "!click": function ($event) {
            $event.stopPropagation()
          },
          keyup: _vm.verifyKeys,
        },
        model: {
          value: _vm.alteration,
          callback: function ($$v) {
            _vm.alteration = $$v
          },
          expression: "alteration",
        },
      }),
      _vm.showInput
        ? _c("vs-icon", {
            staticClass:
              "danger-color hover:animate-bounce transform hover:scale-125",
            attrs: { icon: "clear", size: "1.5rem" },
            on: {
              "!click": function ($event) {
                $event.stopPropagation()
                return _vm.cancelAlteration.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm.showInput
        ? _c("vs-icon", {
            staticClass:
              "primary-color hover:animate-bounce transform hover:scale-125",
            attrs: { icon: "done", size: "1.5rem" },
            on: {
              "!click": function ($event) {
                $event.stopPropagation()
                return _vm.confirmAlteration.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }