<template>
  <div class="footer">
    <div
      :class="`flex items-center p-1 ${
        this.item.type !== 'section'
          ? 'justify-between'
          : 'justify-start gap-2'
      }`"
    >
      <div
        class="flex items-center justify-center"
        v-if="showFeedback && !showScoreInput"
      >
        <vs-button
          v-if="showFeedback"
          type="filled"
          icon="add_comment"
          class="float-left footer-button"
          @click="toggleFeedbackModal"
          v-tooltip.bottom-start="{
            content: $t('adicionar-feedback'),
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>
      </div>

      <div :class="showScoreButtonClass" v-if="showScoreButton">
        <vs-button
          v-if="showScoreButton"
          type="filled"
          icon="plus_one"
          :class="buttonScoreConfigClass"
          @click="toggleScoreInput"
          v-tooltip.bottom-start="{
            content: $t('definir-pontuacao'),
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>

        <vs-input
          class="float-left w-50 ml-1"
          type="number"
          :placeholder="isSmallScreen ? '' : $t('pontuacao')"
          ref="score"
          id="score"
          v-model="score"
          @change="scoreChange"
          v-on:keyup="validateKeyPresses"
          v-show="showScoreInput"
        />

        <vs-button
          type="filled"
          icon="clear"
          class="float-left footer-button danger-color ml-1"
          @click.native="cancelScoreInput"
          v-if="showScoreInput"
        ></vs-button>

        <vs-button
          type="filled"
          icon="done"
          class="float-left footer-button primary-color"
          @click.native="toggleScoreInput"
          v-if="showScoreInput"
        ></vs-button>
      </div>

      <div
        v-if="showInputSizeButton && !showScoreInput"
        class="flex items-center justify-center"
      >
        <v-popover
          v-if="showInputSizeButton"
          class="float-left"
          trigger="manual"
          :open="showInputSize"
          offset="16"
          :auto-hide="true"
          :handle-resize="true"
          @auto-hide="showInputSize = false"
        >
          <vs-button
            type="filled"
            icon="format_size"
            class="float-left footer-button tooltip-target b1 mt-1"
            @click="toggleInputSize"
            v-tooltip.bottom-start="{
              content: $t('tamanho-resposta'),
              delay: { show: 1000, hide: 100 },
            }"
          ></vs-button>

          <template slot="popover">
            <div>
              <vs-row
                :class="
                  showShortInputCheck
                    ? 'cursor-pointer success-color'
                    : 'cursor-pointer'
                "
              >
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="3"
                >
                  <i
                    @click="selectInputCheck('short')"
                    v-if="showShortInputCheck"
                    class="
                      vs-icon
                      notranslate
                      icon-scale
                      vs-button--icon
                      material-icons
                      null
                    "
                    style="font-size: 20px"
                    >check</i
                  >
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="9"
                >
                  <div
                    class="flex bg-gray-200 float-left"
                    style="min-width: 120px"
                    @click="selectInputCheck('short')"
                  >
                    {{ $t('resposta-curta') }}
                  </div>
                </vs-col>
              </vs-row>

              <vs-row
                :class="
                  !showShortInputCheck
                    ? 'cursor-pointer success-color mt-2'
                    : 'cursor-pointer mt-2'
                "
              >
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="3"
                >
                  <i
                    @click="selectInputCheck('long')"
                    v-if="!showShortInputCheck"
                    class="
                      vs-icon
                      notranslate
                      icon-scale
                      vs-button--icon
                      material-icons
                      null
                    "
                    style="font-size: 20px"
                    >check</i
                  >
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="9"
                >
                  <div
                    class="flex bg-gray-200 float-left"
                    style="min-width: 120px"
                    @click="selectInputCheck('long')"
                  >
                    {{ $t('resposta-longa') }}
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </template>
        </v-popover>
      </div>

      <div v-if="!showScoreInput" class="flex items-center justify-center">
        <vs-button
          v-if="showDeleteBtn"
          type="filled"
          icon="delete_outline"
          class="float-left footer-button"
          @click.native="deleteItem"
          v-tooltip.bottom-start="{
            content: $t('excluir-questao'),
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>
        <vs-button
          v-if="showInvalidateBtn"
          type="filled"
          icon="report"
          class="float-left footer-button"
          @click.native="invalidation.showPrompt = true"
          v-tooltip.bottom-start="{
            content: $t('anular-questao-0'),
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>
      </div>

      <div v-if="!showScoreInput" class="flex items-center justify-center">
        <vs-button
          type="filled"
          icon="content_copy"
          class="float-left footer-button"
          @click.native="duplicateItem"
          v-tooltip.bottom-start="{
            content: $t('duplicar-questao'),
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>
      </div>

      <div
        v-if="!showScoreInput && showStoreButton && !showMoreButton"
        class="flex items-center justify-center"
      >
        <vs-button
          type="filled"
          icon="archive"
          class="footer-button"
          @click="storeBaseQuestion"
          v-if="showStoreButton"
        ></vs-button>
      </div>

      <div v-show="this.item.type !== 'section'" class="h-6 w-1 toolbar-more-border"></div>

      <div :class="toolbarRequiredClass" v-if="showRequired && !showScoreInput">
        <div class="flex bg-gray-200">
          <div class="grid grid-flow-col auto-cols-max">
            <div class="flex items-center justify-center">
              <vs-switch v-model="required" />
            </div>

            <div
              v-if="showButtonText"
              class="flex items-center justify-center pl-2"
            >
              <span>{{ $t('obrigatoria') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showMoreButton && !showScoreInput" :class="toolbarMoreClass">
        <vs-dropdown vs-custom-content vs-trigger-click>
          <vs-button
            type="filled"
            icon="more_vert"
            class="footer-button cursor-pointer"
          ></vs-button>

          <vs-dropdown-menu>
            <vs-dropdown-group v-if="showStoreButton" :vs-label="$t('acao')">
              <vs-dropdown-item @click="storeBaseQuestion">
                <div class="flex items-center gap-2 flex-row cursor-pointer">
                  <i class="material-icons" style="font-size: 20px">archive</i>
                  <label class="bg-gray-200 cursor-pointer"
                    >{{ $t('salvar-no-banco-de-questao') }}</label
                  >
                </div>
              </vs-dropdown-item>
            </vs-dropdown-group>

            <vs-dropdown-group :vs-label="$t('configuracao')">
              <vs-dropdown-item
                v-if="this.item.type === 'multiple_choice'"
                @click="toggleSortOptions"
                :style="sortOptions ? 'background-color: #dae1e7' : ''"
              >
                <div class="flex">
                  <div class="w-1/12">
                    <i
                      v-if="sortOptions"
                      class="
                        vs-icon
                        notranslate
                        icon-scale
                        vs-button--icon
                        material-icons
                        null
                      "
                      style="font-size: 20px"
                      >check</i
                    >
                  </div>

                  <div class="w-11/12">
                    <div class="flex bg-gray-200 float-left">
                      {{ $t('embaralhar-opcoes-aleatoriamente') }}
                    </div>
                  </div>
                </div>
              </vs-dropdown-item>

              <vs-dropdown-item class="hover:text-primary">
                <icon-with-expandable-input
                  class="time-duration-footer"
                  placeholder=""
                  inputType="number"
                  :inputMask="['###']"
                  @input="questionDurationChange"
                  buttonClasses="p-0 w-auto h-auto"
                  icon="schedule"
                  v-model="question_duration"
                >
                  <span> {{timeDurationInfo}} </span>
                </icon-with-expandable-input>
              </vs-dropdown-item>

              <vs-dropdown-item class="hover:text-primary" v-if="this.item.type === 'simple_text'">
                <icon-with-expandable-input
                  class="time-duration-footer"
                  placeholder=""
                  inputType="number"
                  :inputMask="['####']"
                  @input="characterLimitChange"
                  buttonClasses="p-0 w-auto h-auto"
                  icon="straighten"
                  v-model="character_limit"
                >
                  <span> {{characterLimitInfo}} </span>
                </icon-with-expandable-input>
              </vs-dropdown-item>

              <vs-dropdown-item class="hover:text-primary" v-if="this.item.type === 'essay'" @click="showEssayLimits">
                <div class="flex items-center gap-2 flex-row cursor-pointer">
                  <i class="material-icons" style="font-size: 20px">wysiwyg</i>
                  <label class="bg-gray-200 cursor-pointer"
                    >Configurar Limites</label
                  >
                </div>
              </vs-dropdown-item>

            </vs-dropdown-group>

            <vs-dropdown-group
              v-if="this.item.type === 'multiple_choice'"
              vs-label="Enumerar Questão"
            >
              <vs-dropdown-item
                v-if="this.item.type === 'multiple_choice'"
                @click="toggleEnumerateQuestionNumber('number')"
                :style="
                  questioEnumerateNumber ? 'background-color: #dae1e7' : ''
                "
              >
                <div class="flex">
                  <div class="w-1/12">
                    <i
                      v-if="questioEnumerateNumber"
                      class="
                        vs-icon
                        notranslate
                        icon-scale
                        vs-button--icon
                        material-icons
                        null
                      "
                      style="font-size: 20px"
                      >check</i
                    >
                  </div>

                  <div class="w-11/12">
                    <div class="flex bg-gray-200 float-left">
                      {{ $t('enumerar-questao-com-numeros') }}
                    </div>
                  </div>
                </div>
              </vs-dropdown-item>

              <vs-dropdown-item
                v-if="this.item.type === 'multiple_choice'"
                @click="toggleEnumerateQuestionNumber('letter')"
                :style="
                  questioEnumerateLetter ? 'background-color: #dae1e7' : ''
                "
              >
                <div class="flex">
                  <div class="w-1/12">
                    <i
                      v-if="questioEnumerateLetter"
                      class="
                        vs-icon
                        notranslate
                        icon-scale
                        vs-button--icon
                        material-icons
                        null
                      "
                      style="font-size: 20px"
                      >check</i
                    >
                  </div>

                  <div class="w-11/12">
                    <div class="flex bg-gray-200 float-left">
                      {{ $t('enumerar-questao-com-letras') }}
                    </div>
                  </div>
                </div>
              </vs-dropdown-item>

            </vs-dropdown-group>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>

    <!--FEEDBACK POPUP -->
    <vs-popup
      :title="$t('adicionar-feedback')"
      :active.sync="showFeedbackModal"
      fullscreen="true"
    >
      <vs-tabs :color="colorx" v-model="tabIndex">
        <vs-tab :label="$t('resposta-correta')">
          <ComplexEditor
            :questionnaire_id="parent_id"
            v-bind:editor_data.sync="correctFeedback.editorData"
            :placeholder="$t('digite-aqui-o-feedback')"
          ></ComplexEditor>
        </vs-tab>
        <vs-tab :label="$t('resposta-incorreta')">
          <ComplexEditor
            :questionnaire_id="parent_id"
            v-bind:editor_data.sync="wrongFeedback.editorData"
            :placeholder="$t('digite-aqui-o-feedback')"
          ></ComplexEditor>
        </vs-tab>
      </vs-tabs>

      <div class="flex flex-wrap float-right">
        <vs-button class="mr-2" @click="updateFeedback">{{ $t('adicionar') }}</vs-button>
        <vs-button type="border" color="danger" @click="toggleFeedbackModal"
          >{{ $t('action.cancel') }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- INVALIDATION POPUP PROMPT -->
    <vs-popup
      class="invalidation-popup"
      :title="$t('anular-questao')"
      color="danger"
      :active.sync="invalidation.showPrompt"
    >
      <div class="flex gap-4 flex-col pb-6" :key="invalidation.key">
        <p class="font-bold">
          {{ $t('tem-certeza-que-deseja-anular-esta-questao') }}
        </p>

        <div>
          <label class="block font-bold text-sm"> {{ $t('justificativa') }} </label>
          <ComplexEditor
            :editor_data.sync="invalidation_justification"
            :placeholder="$t('opcional-justifique-porque-a-questao')"
            :dontClose="true"
          ></ComplexEditor>
        </div>

        <p class="font-bold">
          {{ $t('o-que-devera-ser-feito-com-a-nota') }}
        </p>

        <div>
          <vs-radio class="py-2 w-full" v-model="invalidation_type" vs-name="invalidation_type" vs-value="replace">
            {{ $t('manter') }}
          </vs-radio>

          <vs-radio class="py-2 w-full" v-model="invalidation_type" vs-name="invalidation_type" vs-value="zero">
            {{ $t('zerar') }}
          </vs-radio>
        </div>
      </div>
      <div class="w-full pb-2 flex gap-2 flex-row items-end justify-end">
        <vs-button icon="undo" v-if="itemIsInvalidated()" @click="handleInvalidationPrompt('clear')" type="border" color="primary">
          {{ $t('limpar') }}
        </vs-button>
        <vs-button @click="handleInvalidationPrompt('accept')" type="filled" :color="itemIsInvalidated() ? 'primary' : 'danger'">
          {{ itemIsInvalidated() ? 'Alterar' : 'Anular' }}
        </vs-button>
        <vs-button @click="handleInvalidationPrompt('cancel')" type="border" color="primary">
          {{ $t('voltar') }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import IconWithExpandableInput from '@/components/IconWithExpandableInput.vue'
const ComplexEditor = () => import('./ComplexEditor')
import { isAdmin } from '@/util/Util'

export default {
  components: {
    ComplexEditor,
    IconWithExpandableInput,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    showInvalidateButton: {
      type: Boolean,
      default: false
    },
    cheetEnabled: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    required: false,
    showFeedbackModal: false,
    showScoreInput: false,
    showInputSize: false,
    sortOptions: false,
    questioEnumerateNumber: false,
    questioEnumerateLetter: false,
    inputSize: false,
    score: null,
    question_duration: 0,
    character_limit: 0,
    correctFeedback: {
      editorData: '',
    },
    wrongFeedback: {
      editorData: '',
    },
    isSmallScreen: false,
    showButtonText: false,
    toolbarClass: '',
    toolbarMoreClass: '',
    toolbarRequiredClass: '',
    buttonScoreConfigClass: 'float-left footer-button',
    invalidation_justification: '',
    invalidation_type: 'replace', // or 'zero'
    invalidation: {
      key: 0,
      showPrompt: false
    }
  }),
  methods: {
    deleteItem(event) {
      this.$parent.$emit('footer-DELETE_ITEM', this.item)
    },
    handleInvalidationPrompt(action) {
      switch (action) {
      case 'close':
      case 'cancel':
        this.invalidation.showPrompt = false
        break
      case 'accept':
        this.invalidateItem(this.invalidation_justification, this.invalidation_type)
        this.invalidation.showPrompt = false
        break
      case 'clear':
        this.invalidation_type = null
        this.invalidation_justification = null
        this.invalidation.showPrompt = null
        this.revalidateItem()
      }
    },
    revalidateItem() {
      this.$parent.$emit(
        'footer-REVALIDATE_ITEM',
        {
          item: this.item
        }
      )
    },
    invalidateItem(justification, type) {
      this.$parent.$emit(
        'footer-INVALIDATE_ITEM',
        {
          item: this.item,
          justification: this.invalidation_justification,
          type: this.invalidation_type,
          isUpdating: this.itemIsInvalidated()
        }
      )
    },
    duplicateItem(event) {
      this.$parent.$emit('footer-DUPLICATE_ITEM', this.item)
      this.scrollBar(event)
    },
    toggleFeedbackModal() {
      this.showFeedbackModal = !this.showFeedbackModal
    },
    updateFeedback() {
      this.item.data.correct_feedback = this.correctFeedback.editorData
      this.item.data.wrong_feedback = this.wrongFeedback.editorData
      this.$parent.$emit('footer-ITEM_UPDATED', this.item)
      this.toggleFeedbackModal()
    },
    toggleSortOptions() {
      this.sortOptions = !this.sortOptions
      this.item.data.sort_options = this.sortOptions
      this.$parent.$emit('footer-ITEM_UPDATED', this.item)
    },
    toggleEnumerateQuestionNumber(value) {
      if (value === 'number' && this.item.data.enumerate !== 'number') {
        this.questioEnumerateNumber = true
        this.questioEnumerateLetter = false
        this.item.data.enumerate = 'number'
      } else if (value === 'letter' && this.item.data.enumerate !== 'letter') {
        this.questioEnumerateNumber = false
        this.questioEnumerateLetter = true
        this.item.data.enumerate = 'letter'
      } else {
        this.questioEnumerateNumber = false
        this.questioEnumerateLetter = false
        this.item.data.enumerate = null
      }
      this.$parent.$emit('footer-ITEM_UPDATED', this.item)
    },
    toggleScoreInput() {
      this.showScoreInput = !this.showScoreInput
      if (this.toggleScoreInput) {
        const scoreButtonClass = 'flex items-center justify-center'
        this.showScoreButtonClass = this.showScoreInput
          ? `${scoreButtonClass} col-span-3` : scoreButtonClass
        setTimeout(() => {
          this.$refs.score.$refs.vsinput.focus()
        }, 10)
      }
    },
    toggleInputSize() {
      this.showInputSize = !this.showInputSize
    },
    validateKeyPresses(e) {
      if (e.keyCode === 13) {
        this.toggleScoreInput()
      }
    },
    scoreChange() {
      if (this.score && Number.parseInt(this.score) < 0) {
        this.score = ''
      }
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.item.data.score !== this.score) {
          this.$root.$emit('footer-SCORE_CHANGED', this._.get(this.item, 'id'))
        }
        this.item.data.score = this.score
        this.$parent.$emit('footer-ITEM_UPDATED', this.item)
      }, 1000)
    },
    questionDurationChange() {
      if (
        this.question_duration &&
        Number.parseInt(this.question_duration) < 0
      ) {
        this.question_duration = 0
      }
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.item.data.question_duration = this.question_duration
        this.$parent.$emit('footer-ITEM_UPDATED', this.item)
      }, 500)
    },
    characterLimitChange() {
      if (
        this.question_duration &&
        Number.parseInt(this.character_limit) < 0
      ) {
        this.character_limit = 0
      }
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.item.data.character_limit = this.character_limit
        this.$parent.$emit('footer-ITEM_UPDATED', this.item)
      }, 500)
    },
    cancelScoreInput() {
      this.toggleScoreInput()
    },
    selectInputCheck(size) {
      if (this.inputSize !== size) {
        this.showInputSize = false
        this.inputSize = size
        this.item.data.input_size = this.inputSize
        this.$parent.$emit('footer-ITEM_UPDATED', this.item)
      }
    },
    checkToolbarClass() {
      const toolbarWidth = this.isSmallScreen ? 8 : 9
      const toolbarMoreWidth = this.isSmallScreen ? 4 : 3
      this.toolbarClass = this.showRequired
        ? `mr-2 w-${toolbarWidth}/12 mt-2 mb-1`
        : 'w-full mt-2 mb-1'
      this.toolbarRequiredClass =
        this.windowWidth > 1000
          ? 'md:col-span-2 lg:col-span-2 flex items-center justify-center'
          : 'flex items-center justify-center'
      this.toolbarMoreClass =
        this.windowWidth > 1000
          ? 'flex items-center justify-center'
          : 'flex items-center justify-center'
      this.showButtonText = this.windowWidth > 1000
      this.showScoreButtonClass = 'flex items-center justify-center'
    },
    scrollBar(event) {
      window.scrollTo(
        0,
        window.document.documentElement.scrollTop +
          this.$el.parentElement.offsetHeight +
          50
      )
    },
    storeBaseQuestion() {
      // This is a sin, this component is dirty, the hierarchy is violated,
      // may the grand refactor come and save us from this mess.
      this.$root.$emit('store-question', this.item)
    },
    /**
     * This is run when the global event for opening the prompt is emitted,
     * and of course we verify if the item of this footer is the one that is
     * supposed to be having its footer be opened.
     */
    openInvalidationPrompt(item) {
      if (this.item.id === item.id) {
        this.invalidation.key++
        this.invalidation.showPrompt = true
      }
    },
    itemIsInvalidated() {
      const invalidated = this._.get(this.item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        return true
      }

      return false
    },
    showEssayLimits() {
      this.$parent.showEssayLimits()
    }
  },
  watch: {
    required(newValue) {
      this.item.data.required = newValue
      this.$parent.$emit('footer-ITEM_UPDATED', this.item)
    },
    windowWidth() {
      this.isSmallScreen = this.windowWidth < 550
      this.checkToolbarClass()
    },
    'invalidation.showPrompt'(val) {
      if (val) {
        const invalidationType = this._.get(this.item, 'data.invalidated.type', 'replace')
        const invalidationJustification = this._.get(this.item, 'data.invalidated.justification', '')

        this.invalidation_type = invalidationType
        this.invalidation_justification = invalidationJustification
      } else {
        this.invalidation_type = 'replace'
        this.invalidation_justification = ''
      }
    },
    score(newValeu) {
      if (newValeu && newValeu > 0) {
        this.buttonScoreConfigClass = 'float-left footer-button success-color'
      } else {
        this.buttonScoreConfigClass = 'float-left footer-button'
      }
    }
  },
  computed: {
    showDeleteBtn() {
      return !this.showInvalidateButton || (isAdmin() && this.cheetEnabled)
    },
    showInvalidateBtn() {
      return this.showInvalidateButton  || (isAdmin() && this.cheetEnabled)
    },
    showRequired() {
      return this.item.type !== 'section'
    },
    showFeedback() {
      return this.item.type !== 'section'
    },
    showMoreButton() {
      return (
        this.item.type !== 'section' &&
        (
          this.item.type === 'multiple_choice' ||
          this.item.type === 'simple_text' ||
          this.item.type === 'check_box' ||
          this.item.type === 'gap' ||
          this.item.type === 'essay'
        )
      )
    },
    showStoreButton() {
      return this.item.type !== 'section'
    },
    showScoreButton() {
      return this.item.type !== 'section'
    },
    showInputSizeButton() {
      return this.item.type === 'simple_text'
    },
    showShortInputCheck() {
      return this.inputSize === 'short'
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    timeDurationInfo() {
      const duration = (
        this.item &&
        this.item.data &&
        this.item.data.question_duration &&
        !this.isEmpty(this.item.data.question_duration) &&
        parseFloat(this.item.data.question_duration)
      ) || 0

      if (duration && duration !== 0) {
        return this.$t('limite-de-tempo-duration-min', [duration])
      }

      return this.$t('limite-de-tempo')
    },
    characterLimitInfo() {
      const characterLimit = (
        this.item &&
        this.item.data &&
        this.item.data.character_limit &&
        !this.isEmpty(this.item.data.character_limit) &&
        parseFloat(this.item.data.character_limit)
      ) || 0

      if (characterLimit && characterLimit !== 0) {
        return this.$t('qtd-max-caracteres-characterlimit-caract', [characterLimit])
      }

      return this.$t('qtd-max-caracteres')
    }
  },
  created() {
    if (this.item !== null) {
      if (this.item.type !== 'section') {
        this.required = this.item.data.required
        this.score = this.item.data.score

        if (
          this.item &&
          this.item.data
        ) {
          if ('question_duration' in this.item.data) {
            this.question_duration = this.item.data.question_duration
          }

          if ('character_limit' in this.item.data) {
            this.character_limit = this.item.data.character_limit
          }
        }

        this.sortOptions = this.item.data.sort_options
        this.inputSize = this.item.data.input_size
        this.correctFeedback.editorData = this.item.data.correct_feedback
        this.wrongFeedback.editorData = this.item.data.wrong_feedback

        if (!this.isEmpty(this.item.data.enumerate)) {
          this.questioEnumerateNumber = this.item.data.enumerate === 'number'
          this.questioEnumerateLetter = this.item.data.enumerate === 'letter'
        }
      }
    }
    this.checkToolbarClass()
    this.$root.$on('footer-open-invalidation-prompt', this.openInvalidationPrompt)
  },
  destroyed() {
    // console.debug('destroying footer global listeners item-id:', this.item.id)
    this.$root.$off('footer-open-invalidation-prompt', this.openInvalidationPrompt)
  }
}
</script>

<style lang="scss" scoped>
.time-duration-footer {
  & /deep/ button.footer-button i.vs-icon {
    font-size: 20px;
  }
}

.invalidation-popup {
  & /deep/ .vs-popup--content {
    @apply pb-0;
    @apply overflow-x-hidden;
  }
}
</style>

<style lang="sass">
.toolbar-more-border
  border-left: 1px solid #dadce0

.footer
  border-top: 1px solid #dadce0

  &-button
    color: #5f6368
    background: transparent !important

  &-button .vs-icon
    font-size: 25px

.vs-dropdown--custom > li
  border-top: none
  min-width: 350px

.vs-con-dropdown
  cursor: pointer

.vs-button > i
  z-index: 2
</style>
