<template>
  <div class="flex items-center justify-left gap-2">
    <vs-button
      v-if="showIcon"
      type="filled"
      :icon="icon"
      :class="`footer-button text-inherit ${buttonClasses}`"
      @click.capture.stop="openInput"
      v-tooltip.bottom-start="{
        content: 'Definir pontuação',
        delay: { show: 1000, hide: 100 },
      }"
    ></vs-button>

    <div v-if="!showInput" class="flex-grow" @click.capture.stop="openInput">
      <slot></slot>
    </div>

    <vs-input
      :type="inputType"
      v-mask="inputMask"
      @click.capture.stop=""
      class="expandable-input flex-grow"
      :placeholder="placeholder"
      ref="input"
      v-model="alteration"
      v-on:keyup="verifyKeys"
      v-show="showInput"
    />

    <vs-icon
      icon="clear"
      size="1.5rem"
      class="danger-color hover:animate-bounce transform hover:scale-125"
      @click.capture.stop="cancelAlteration"
      v-if="showInput"
    />

    <vs-icon
      icon="done"
      size="1.5rem"
      class="primary-color hover:animate-bounce transform hover:scale-125"
      @click.capture.stop="confirmAlteration"
      v-if="showInput"
    />

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'read_more',
    },
    iconPack: {
      type: String,
      default: 'feather',
    },
    placeholder: {
      type: String,
      default: '',
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputMask: {
      type: Array,
      default: () => ['XXXXXXXXXX']
    }
  },

  data: () => ({
    alteration: null,
    showIcon: true,
    showInput: false,
  }),

  watch: {
    value(value) {
      this.alteration = value
    },
  },

  methods: {
    verifyKeys(e) {
      if (e.keyCode === 13) {
        this.confirmAlteration()
      }
    },
    openInput() {
      this.$emit('show-input')
      this.showInput = true
      if (this.showInput) {
        setTimeout(() => {
          this.$refs.input.$refs.vsinput.focus()
        }, 10)
      }
    },
    closeInput() {
      this.$emit('hide-input')
      this.showInput = false
    },
    confirmAlteration() {
      // if (this.inputType === 'number' && /\D/.test(this.alteration)) {
      //   this.notifyWarning(this.$vs, 'O valor deve ser numérico')
      //   this.alteration = this.value
      //   return
      // }
      this.$emit('input', this.alteration)
      this.closeInput()
    },
    cancelAlteration() {
      this.alteration = this.value
      this.closeInput()
    },
  },

  created() {
    this.alteration = this.value
  },
}
</script>

<style lang="scss" scoped>
.expandable-input  {
  width: auto !important;
  & /deep/ input {
    padding: 0.4rem !important;
  }
}
</style>